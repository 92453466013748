import React, { useContext, Fragment, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { AuthContext } from "../../context/auth-context";
import "./NavLinks.css";
import AuthNavLink from "./AuthNavLink";

import { useLocation } from "react-router-dom";

import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import { getUserLanguages } from "../../../actions/users";

import { connect } from "react-redux";

const NavLinks = ({ logged_user, getUserLanguages }) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (!logged_user || !logged_user.length) getUserLanguages();
  }, []);

  const location = useLocation();

  return (
    <div className="navbar">
      {auth.isLoggedIn && (
        <Fragment>
          <AuthNavLink title="Inbox" to="/inbox">
            <Icon>inbox_rounded</Icon>
          </AuthNavLink>
          <AuthNavLink title="Dashboard" to="/dashboard">
            <Icon>network_check_rounded</Icon>
          </AuthNavLink>
          <AuthNavLink title="Dashbox" to="/dashbox">
            <Icon>all_inbox</Icon>
          </AuthNavLink>
          <AuthNavLink title="Untracked Sources Inbox" to="/usi">
            <Icon>gps_off_rounded</Icon>
          </AuthNavLink>
          <AuthNavLink title="Source Manager" to="/sources">
            <Icon>public_rounded</Icon>
          </AuthNavLink>
          <div className="dropdown">
            <button title="Configuration" className="dropbtn">
              <Icon>tune_rounded</Icon>
              <Icon>expand_more</Icon>
            </button>
            <div className="dropdown-content">
              <AuthNavLink to="/users" role="Admin" style={{ display: "flex", verticalAlign: "middle" }}>
                <Icon>assignment_ind_rounded</Icon> <span className="icon-descr">Users</span>
              </AuthNavLink>
              <AuthNavLink to="/iwm_users" role="Admin" style={{ display: "flex", verticalAlign: "middle" }}>
                <Icon>recent_actors</Icon> <span className="icon-descr">IWM Users</span>
              </AuthNavLink>
              <AuthNavLink to="/alert-logs" role="Admin">
                <Icon>access_alarms_rounded</Icon> <span className="icon-descr">Logs</span>
              </AuthNavLink>
              <AuthNavLink to="/bin">
                <Icon>delete</Icon> <span className="icon-descr">Bin</span>
              </AuthNavLink>
              <AuthNavLink to="/dash_childs">
                <Icon>account_balance_wallet</Icon> <span className="icon-descr">Dash Mothers</span>
              </AuthNavLink>
              <AuthNavLink to="/tag_manager" role="Editor Plus">
                <Icon>local_offer</Icon> <span className="icon-descr">Tag Manager</span>
              </AuthNavLink>
              <AuthNavLink to="/tier_triggers" role="Admin">
                <Icon>ballot</Icon> <span className="icon-descr">Tier Triggers</span>
              </AuthNavLink>
              <AuthNavLink to="/exclusion_rules" role="Admin">
                <Icon>delete_forever</Icon> <span className="icon-descr">Exclusion Rules</span>
              </AuthNavLink>
              <AuthNavLink to="/jur_tags" role="Admin">
                <Icon>explore</Icon> <span className="icon-descr">Jurisdictions</span>
              </AuthNavLink>
            </div>
          </div>
        </Fragment>
      )}
      {auth.isLoggedIn && (
        <Fragment>
          <a onClick={auth.logout} title="Logout">
            <Icon>exit_to_app</Icon>
          </a>
          {location.pathname === "/inbox" && logged_user.confirmed && (
            <Fragment>
              <div
                style={{
                  display: "inline",
                  float: "right",
                  padding: "5px 10px 5px 10px",
                  height: "60px",
                  verticalAlign: "middle",
                  backgroundColor: "black",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "10px"
                }}
              >
                TOTAL PROCESSED BY USER
                <br />
                <Grid container>
                  <Grid item md={6}>
                    <span
                      style={{
                        color: "green",
                        fontWeight: "bold",
                        fontSize: "18px"
                      }}
                    >
                      {logged_user.confirmed.toLocaleString("en").replace(",", "’")}
                    </span>
                    <br />
                    <span
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "8px",
                        color: "lightgreen"
                      }}
                    >
                      CONFIRMED
                    </span>
                  </Grid>
                  <Grid item md={6}>
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "18px"
                      }}
                    >
                      {logged_user.rejected.toLocaleString("en").replace(",", "’")}
                    </span>
                    <br />
                    <span
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "8px",
                        color: "lightcoral"
                      }}
                    >
                      REJECTED
                    </span>
                  </Grid>
                </Grid>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};
const useStyles = makeStyles({
  dropbtn: {}
});

const mapStateToProps = (state) => ({
  logged_user: state.users.logged_user
});

const mapDispatchToProps = (dispatch) => ({
  getUserLanguages: () => dispatch(getUserLanguages())
});
export default connect(mapStateToProps, mapDispatchToProps)(NavLinks);
