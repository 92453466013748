import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getLanguages } from "../actions/related";
import {
  addIwmUser,
  clearCurrentIwmUser,
  clearErrorIwmUsers,
  deleteIwmUser,
  getIwmUsers,
  setCurrentIwmUser,
  setOpenIwmUser,
  updateIwmUser
} from "../actions/iwm_users";

const emptyIwmUser = {
  pronoun: "",
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  showPassword: false,
  admin: false
};

const IwmIwmUserForm = ({
  current,
  open,
  clearCurrentIwmUser,
  updateIwmUser,
  setOpenIwmUser,
  addIwmUser,
  loading,
  allLanguages,
  getLanguages
}) => {
  const classes = useStyles();
  const [iwm_user, setIwmUser] = useState(emptyIwmUser);

  const {
    pronoun,
    firstname,
    lastname,
    email,
    password,
    showPassword,
    admin,
    disabled
  } = iwm_user;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  useEffect(() => {
    if (!allLanguages || !allLanguages.length) getLanguages();
    if (current !== null) {
      setIwmUser(current);
    } else {
      setIwmUser(emptyIwmUser);
    }
  }, [current, open]);

  function handleClose() {
    setOpenIwmUser(false);
    clearCurrentIwmUser();
  }
  function onSubmit(e) {
    e.preventDefault();

    try {
      if (!current) {
        addIwmUser(iwm_user);
      } else {
        updateIwmUser(iwm_user);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const clearAll = () => {
    setIwmUser(emptyIwmUser);
    clearCurrentIwmUser();
  };

  const onChange = (e, newVal = null) => {
    let val;
    if (e.target.name === "admin" || e.target.name === "disabled") {
      setIwmUser({ ...iwm_user, [e.target.name]: e.target.checked ? 1 : 0 });
    } else {
      val = e.target.value;
      setIwmUser({ ...iwm_user, [e.target.name]: val });
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={true}
        style={
          !useMediaQuery(useTheme().breakpoints.down("sm"))
            ? { width: "40%", height: "35%", margin: "auto" }
            : {}
        }
        open={current || open ? true : false}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle
          id="max-width-dialog-title"
          color="primary"
          className={classes.formHeader}
        >
          {!current && open
            ? "New IwmUser"
            : current && !open
            ? "Edit IwmUser"
            : ""}
        </DialogTitle>

        <DialogContent dividers={true}>
          <form onSubmit={onSubmit} autocomplete="off">
            <input
              autocomplete="false"
              name="hidden"
              type="text"
              style={{ display: "none" }}
            />
            <input type="submit" style={{ display: "none" }} />
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={2}>
                <TextField
                  margin="dense"
                  name="pronoun"
                  label="Pronoun"
                  value={pronoun}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  autocomplete="off"
                />
              </Grid>{" "}
              <Grid item xs={12} md={5}>
                <TextField
                  margin="dense"
                  name="firstname"
                  label="First Name"
                  value={firstname}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  autocomplete="off"
                />
              </Grid>{" "}
              <Grid item xs={12} md={5}>
                <TextField
                  margin="dense"
                  name="lastname"
                  label="Last Name"
                  value={lastname}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  autocomplete="off"
                />
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="email"
                  label="E-mail"
                  autocomplete="off"
                  value={email}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                >
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    type={showPassword ? "text" : "password"}
                    margin="dense"
                    name="password"
                    id="password"
                    autocomplete="off"
                    onChange={onChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) =>
                            setIwmUser({
                              ...iwm_user,
                              showPassword: !iwm_user.showPassword
                            })
                          }
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword ? (
                            <Icon>visibility</Icon>
                          ) : (
                            <Icon>visibility_off</Icon>
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    className={classes.textField}
                    labelWidth={70}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={disabled}
                      onChange={onChange}
                      value="1"
                      name="disabled"
                      color="primary"
                    />
                  }
                  label="Disabled?"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={admin}
                      name="admin"
                      color="primary"
                      value="1"
                      onChange={onChange}
                    />
                  }
                  label="Admin?"
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.formFooter}>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Save
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Cancel
          </Button>
        </DialogActions>
        <Fragment>
          <CssBaseline />
        </Fragment>
      </Dialog>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px"
  },
  modalPaper: {
    width: "10%"
  },

  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  formHeader: {
    backgroundColor: "#002060",
    color: "white"
  },
  formFooter: {
    backgroundColor: "gainsboro",
    padding: "10px"
  }
}));

const mapStateToProps = (state) => ({
  iwm_users: state.iwm_users.all,
  loading: state.iwm_users.loading,
  error: state.iwm_users.error,
  current: state.iwm_users.current,
  open: state.iwm_users.open,
  allLanguages: state.related.languages
});

const mapDispatchToProps = (dispatch) => ({
  deleteIwmUser: (id) => dispatch(deleteIwmUser(id)),
  getIwmUsers: () => dispatch(getIwmUsers()),
  clearErrorIwmUsers: () => dispatch(clearErrorIwmUsers()),
  setCurrentIwmUser: (iwm_user) => dispatch(setCurrentIwmUser(iwm_user)),
  setOpenIwmUser: (should) => dispatch(setOpenIwmUser(should)),
  clearCurrentIwmUser: () => dispatch(clearCurrentIwmUser()),
  addIwmUser: (iwm_user) => dispatch(addIwmUser(iwm_user)),
  updateIwmUser: (iwm_user) => dispatch(updateIwmUser(iwm_user)),
  getLanguages: () => dispatch(getLanguages())
});
export default connect(mapStateToProps, mapDispatchToProps)(IwmIwmUserForm);
