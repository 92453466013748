const initialState = {
  all: [],
  current: null,
  filtered: null,
  filter: null,
  error: null,
  loading: true,
  bypassIo: false,
  open: false,
  iwm_user_languages: [],
  logged_iwm_user: {}
};

const iwm_users = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_IWM_USER":
      return { ...state, all: [...state.all, action.payload] };
    case "DELETE_IWM_USER":
      return {
        ...state,
        all: state.all.filter((iwm_user) => iwm_user.id !== action.payload)
      };
    case "UPDATE_IWM_USER":
      return {
        ...state,
        all: state.all.map((iwm_user) =>
          iwm_user.id !== action.payload.id ? iwm_user : action.payload
        )
      };
    case "GET_IWM_USERS":
      return { ...state, all: action.payload };
    case "GET_IWM_USER_LANGUAGES":
      return { ...state, iwm_user_languages: action.payload };
    case "GET_IWM_USER_DETAILS":
      return { ...state, logged_iwm_user: action.payload };
    case "CURRENT_IWM_USER":
      return { ...state, current: action.payload };
    case "CLEAR_CURRENT_IWM_USER":
      return { ...state, current: null };
    case "SET_BYPASS_IO_IWM_USERS":
      return { ...state, bypassIo: action.payload };
    case "SET_OPEN_IWM_USER":
      return { ...state, open: action.payload };
    case "ADD_CONFIRMED":
      if (!state.logged_iwm_user || !state.logged_iwm_user.confirmed) return state;
      else
        return {
          ...state,
          logged_iwm_user: {
            ...state.logged_iwm_user,
            confirmed: state.logged_iwm_user.confirmed + 1
          }
        };
    case "ADD_REJECTED":
      if (!state.logged_iwm_user || !state.logged_iwm_user.rejected) return state;
      else
        return {
          ...state,
          logged_iwm_user: {
            ...state.logged_iwm_user,
            rejected: state.logged_iwm_user.rejected + 1
          }
        };
    case "ERROR_IWM_USERS":
      let tmpError = action.payload;
      try {
        tmpError = JSON.parse(tmpError.response.data.message);
        tmpError.list = tmpError.list
          ? "<ul>" +
            tmpError.list.map((error) => "<li>" + error + "</li>").join("") +
            "</ul>"
          : "";
      } catch (err) {
        tmpError = {
          msg: tmpError.response
            ? tmpError.response.data.message
            : tmpError.toString(),
          list: ""
        };
      }

      return { ...state, error: tmpError };

    case "CLEAR_ERROR_IWM_USERS":
      return { ...state, error: null };
    case "LOADING_IWM_USERS":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default iwm_users;
