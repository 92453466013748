import React, { useEffect, useState, useContext, Fragment } from "react";
import { connect } from "react-redux";

import {
  deleteIwmUser,
  getIwmUsers,
  clearErrorIwmUsers,
  setCurrentIwmUser,
  clearCurrentIwmUser,
  setBypassIoIwmUsers
} from "../actions/iwm_users";

import subscribeTo from "../utils/subscribeTo";
import { AuthContext } from "../shared/context/auth-context";
import IwmUserForm from "./IwmUserForm";
import AddIwmUserBtn from "./AddIwmUserBtn";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Icon, Breadcrumbs, Link } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useConfirm } from "material-ui-confirm";
import { Helmet } from "react-helmet";

const IwmIwmUsers = ({
  iwm_users,
  loading,
  error,
  current,
  bypassIo,
  clearErrorIwmUsers,
  setCurrentIwmUser,
  clearCurrentIwmUser,
  setBypassIoIwmUsers,
  deleteIwmUser,
  getIwmUsers,
  dispatch
}) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const confirm = useConfirm();

  useEffect(() => {
    if ((!iwm_users || iwm_users.length === 0) && !error) getIwmUsers();
    if (!loading) {
      subscribeTo("iwm_usersChanged", (err, data) => {
        if (!bypassIo) getIwmUsers();
        else {
          getIwmUsers();
          setBypassIoIwmUsers(false);
        }
      });
      return () => {
        subscribeTo("iwm_usersChanged", null);
      };
    }
  }, [loading, bypassIo]);

  const onClickDelete = (confirm, iwm_user) => {
    confirm({
      description: `Are you sure you want to delete the iwm_user "${iwm_user.email}"?`,
      title: "WARNING",
      cancellationText: "Cancel",
      confirmationText: "Confirm"
    }).then(() => deleteIwmUser(iwm_user.id));
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>IWM Users - ASPU</title>
      </Helmet>
      <ErrorSnack error={error} clearError={clearErrorIwmUsers} />
      <React.Fragment>
        <CssBaseline />
        <IwmUserForm
          current={current}
          clearCurrentIwmUser={clearCurrentIwmUser}
        />
        <Container maxWidth="xl">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" href="/">
              Configuration
            </Link>
            <Link color="textPrimary" href="/iwm_users" aria-current="page">
              Iwm Users
            </Link>
          </Breadcrumbs>

          {iwm_users.length !== 0 && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell>Pronoun</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell># Searches</TableCell>
                    <TableCell>Saved Searches</TableCell>
                    <TableCell>Saved Folders</TableCell>
                    <TableCell>Last Login</TableCell>

                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {iwm_users.map((row) => (
                    <TableRow
                      key={row.name}
                      style={{
                        ...(row.disabled && {
                          textDecoration: "line-through"
                        })
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.pronoun}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.firstname}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.lastname}
                      </TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.number_searches}</TableCell>
                      <TableCell>{row.n_ss}</TableCell>
                      <TableCell>{row.n_sf}</TableCell>

                      <TableCell>
                        {row.last_login &&
                          new Date(row.last_login).toUTCString()}
                      </TableCell>

                      <TableCell align="right">
                        {row.id && (
                          <Fragment>
                            <a onClick={() => setCurrentIwmUser(row)}>
                              <Icon>edit</Icon>
                            </a>{" "}
                            <a onClick={(e) => onClickDelete(confirm, row)}>
                              <Icon color="secondary">delete</Icon>
                            </a>
                          </Fragment>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <AddIwmUserBtn />
        </Container>
      </React.Fragment>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableHead: {
    backgroundColor: "#eee"
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px"
  },
  modalPaper: {
    width: "10%"
  }
});

const mapStateToProps = (state) => ({
  iwm_users: state.iwm_users.all,
  loading: state.iwm_users.loading,
  error: state.iwm_users.error,
  current: state.iwm_users.current,
  bypassIo: state.iwm_users.bypassIo
});

const mapDispatchToProps = (dispatch) => ({
  deleteIwmUser: (id) => dispatch(deleteIwmUser(id)),
  getIwmUsers: () => dispatch(getIwmUsers()),
  clearErrorIwmUsers: () => dispatch(clearErrorIwmUsers()),
  setCurrentIwmUser: (iwm_user) => dispatch(setCurrentIwmUser(iwm_user)),
  clearCurrentIwmUser: () => dispatch(clearCurrentIwmUser()),
  setBypassIoIwmUsers: (bypass) => dispatch(setBypassIoIwmUsers(bypass))
});
export default connect(mapStateToProps, mapDispatchToProps)(IwmIwmUsers);
