import axios from "axios";

import { addConfirmed, addRejected } from "./users";

export const deleteArticle = (article, posfix = "") => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: true });
    try {
      const responseData = await axios.delete(process.env.REACT_APP_BACKEND_URL + "/articles/" + article.id);
      dispatch(addRejected());
      dispatch({
        type: "DELETE_ARTICLE" + (posfix ? posfix : ""),
        payload: article
      });
      //dispatch({ type: "FILTER_ARTICLE" + posfix, payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      console.log(err);
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const approveArticle = (article) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: true });
    try {
      const responseData = await axios.put(process.env.REACT_APP_BACKEND_URL + "/articles/approve/" + article.id);
      dispatch(addConfirmed());
      dispatch({ type: "APPROVE_ARTICLE", payload: article });
      dispatch({ type: "FILTER_ARTICLE", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: JSON.parse(err.response.data.message).message });
      if (JSON.parse(err.response.data.message).code === "POTMOTHER")
        dispatch({
          type: "UPDATE_ARTICLE",
          payload: {
            ...article,
            dash_parent_article_id: JSON.parse(err.response.data.message).dash_parent_article_id,
            dash_mother: JSON.parse(err.response.data.message).dash_mother
          }
        });
      dispatch({ type: "FILTER_ARTICLE", payload: false });
      dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const setBackToInbox = (article) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/articles/backToInbox/" + article.id + "/" + article.parent_article_id_old
      );

      dispatch({ type: "BACK_TO_INBOX", payload: article });
      dispatch({ type: "FILTER_ARTICLE", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const setBackToDashboard = (article) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "/articles/backToDashboard/" +
          article.id +
          "/" +
          article.parent_article_id_old
      );

      dispatch({ type: "BACK_TO_DASHBOARD", payload: article });
      dispatch({ type: "FILTER_ARTICLE", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const reassignMother = (mother, children, posfix) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: true });
    try {
      const responseData = await axios.post(process.env.REACT_APP_BACKEND_URL + "/articles/reassignMother", {
        mother: { id: mother.id },
        children: children.map((ch) => ch.id)
      });
      dispatch({
        type: "REASSIGN_MOTHER" + (posfix ? posfix : ""),
        payload: { mother, children }
      });
      if (!posfix) dispatch({ type: "FILTER_ARTICLE", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
      dispatch({ type: "CLEAR_REASSIGN" });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const assignToMotherInboxDashboard = (mother, child, convert) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/articles/assignToMotherInboxDashboard",
        {
          mother,
          child,
          convert
        }
      );
      dispatch({
        type: "ASSIGN_TO_MOTHER_INBOX_DASHBOARD",
        payload: { mother, child, convert }
      });

      dispatch({ type: "LOADING_ARTICLES", payload: false });
      dispatch({ type: "CLEAR_REASSIGN" });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const removeDashMother = (child) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: true });
    try {
      const responseData = await axios.post(process.env.REACT_APP_BACKEND_URL + "/articles/removeDashMother", {
        child
      });
      dispatch({
        type: "REMOVE_DASH_MOTHER",
        payload: { child }
      });

      dispatch({ type: "LOADING_ARTICLES", payload: false });
      dispatch({ type: "CLEAR_REASSIGN" });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const removeFromMother = (article, posfix) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: true });
    try {
      const responseData = await axios.post(process.env.REACT_APP_BACKEND_URL + "/articles/removeFromMother", {
        article
      });
      dispatch({
        type: "REMOVE_FROM_MOTHER" + (posfix ? posfix : ""),
        payload: { ...responseData.data.article, position: article.position }
      });
      if (!posfix) dispatch({ type: "FILTER_ARTICLE", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
      dispatch({ type: "CLEAR_REASSIGN" });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};
export const removeMultipleFromMother = (mother, articles, posfix) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: true });
    try {
      const responseData = await axios.post(process.env.REACT_APP_BACKEND_URL + "/articles/removeMultipleFromMother", {
        mother,
        articles
      });
      dispatch({
        type: "REMOVE_MULTIPLE_FROM_MOTHER" + (posfix ? posfix : ""),
        payload: { mother, articles }
      });
      if (!posfix) dispatch({ type: "FILTER_ARTICLE", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
      dispatch({ type: "CLEAR_REASSIGN" });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};
export const convertToMother = (article, posfix = "") => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: true });
    try {
      const responseData = await axios.post(process.env.REACT_APP_BACKEND_URL + "/articles/convertToMother", {
        article
      });
      dispatch({
        type: "CONVERT_TO_MOTHER" + (posfix ? posfix : ""),
        payload: article
      });
      if (!posfix) dispatch({ type: "FILTER_ARTICLE", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
      dispatch({ type: "CLEAR_REASSIGN" });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const setBypassIoArticles = (should) => ({
  type: "SET_BYPASS_IO_ARTICLES",
  payload: should
});

export const setOpenArticle = (open) => ({
  type: "SET_OPEN_ARTICLE",
  payload: open
});

export const updateArticle = (article) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: true });
    try {
      const responseData = await axios.post(process.env.REACT_APP_BACKEND_URL + "/articles/updateArticle", {
        article: { ...article, articles: null }
      });
      dispatch({
        type: "UPDATE_ARTICLE",
        payload: {
          ...responseData.data.article,
          translated_header: responseData.data.article.translated_header
        }
      });
      dispatch({ type: "FILTER_ARTICLE", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
      dispatch({ type: "CLEAR_CURRENT_ARTICLE" });
    } catch (err) {
      console.log(err);
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const addArticle = (article) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: true });
    try {
      const responseData = await axios.post(process.env.REACT_APP_BACKEND_URL + "/articles/addArticle", { article });
      dispatch({ type: "ADD_ARTICLE", payload: responseData.data.article });
      dispatch({ type: "FILTER_ARTICLE", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
      dispatch({ type: "SET_OPEN_ARTICLE", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "SET_BYPASS_IO_ARTICLES", payload: false });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const setCurrentArticle = (article) => {
  return async (dispatch) => {
    dispatch({ type: "CURRENT_ARTICLE", payload: article });
  };
};

export const setFilters = (filters, posfix = "") => ({
  type: "FILTER_ARTICLE" + (posfix ? posfix : ""),
  payload: filters
});

export const clearCurrentArticle = () => ({ type: "CLEAR_CURRENT_ARTICLE" });

export const clearErrorArticles = () => ({ type: "CLEAR_ERROR_ARTICLES" });

export const getArticles = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    try {
      const responseData = await axios.get(process.env.REACT_APP_BACKEND_URL + "/articles");

      dispatch({ type: "GET_ARTICLES", payload: responseData.data.articles });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const getInboxArticles = (date) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    try {
      const responseData = await axios.get(process.env.REACT_APP_BACKEND_URL + "/articles/inbox/" + date);

      dispatch({
        type: "GET_INBOX_ARTICLES",
        payload: responseData.data.mothers
      });

      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const getDashboardArticles = (filters = null) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    try {
      let responseData;
      if ((!filters || !filters.startDate) && !filters.id)
        responseData = await axios.get(process.env.REACT_APP_BACKEND_URL + "/articles/dashboard/all");
      else responseData = await axios.post(process.env.REACT_APP_BACKEND_URL + "/articles/dashboard", { filters });

      dispatch({
        type: "GET_DASHBOARD_ARTICLES",
        payload: { ...responseData.data, dashbox: filters.dashbox }
      });

      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const getDashboxArticles = (filters = null) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    try {
      let responseData;
      if (!filters || !filters.startDate)
        responseData = await axios.get(process.env.REACT_APP_BACKEND_URL + "/articles/dashboard/all");
      else
        responseData = await axios.post(process.env.REACT_APP_BACKEND_URL + "/articles/dashboard", {
          filters: { ...filters, dashbox: true }
        });

      dispatch({
        type: "GET_INBOX_ARTICLES",
        payload: responseData.data.mothers
      });

      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const getBinArticles = (type) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    try {
      const responseData = await axios.get(process.env.REACT_APP_BACKEND_URL + "/articles/bin/all/" + type);

      dispatch({
        type: "GET_BIN_ARTICLES",
        payload: responseData.data.mothers
      });

      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const getUntrackedInbox = (tier) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    try {
      const responseData = await axios.get(process.env.REACT_APP_BACKEND_URL + "/articles/untracked_inbox/" + tier);

      dispatch({
        type: "GET_UNTRACKED_INBOX_ARTICLES",
        payload: responseData.data.mothers
      });

      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const removeFromUSI = (article_id) => {
  return async (dispatch) => {
    dispatch({ type: "REMOVE_FROM_USI", payload: article_id });
  };
};

export const addSourceToArticles = (publisher, source) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    try {
      const responseData = await axios.post(process.env.REACT_APP_BACKEND_URL + "/articles/addSourceToArticles", {
        publisher,
        source
      });

      dispatch({
        type: "ADD_SOURCE_TO_ARTICLES",
        payload: { publisher, source }
      });

      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const removeJur = (article, jur, posfix = "") => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    try {
      const responseData = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/articles/removeJur/" + article.id + "/" + jur.id
      );

      dispatch({
        type: "REMOVE_JUR" + (posfix ? posfix : ""),
        payload: { article_id: article.id, jur_id: jur.id }
      });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      console.log(err);
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const updateJurs = (article, jurs, posfix = "") => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    try {
      const responseData = await axios.post(process.env.REACT_APP_BACKEND_URL + "/articles/updateJurs", {
        article_id: article.id,
        jurs: jurs
      });

      dispatch({
        type: "UPDATE_JURS" + (posfix ? posfix : ""),
        payload: { article_id: article.id, jurs: jurs }
      });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      console.log(err);
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const removeTag = (article, tag, posfix = "") => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    try {
      const responseData = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/articles/removeTag/" + article.id + "/" + tag.id
      );

      dispatch({
        type: "REMOVE_TAG" + (posfix ? posfix : ""),
        payload: { article_id: article.id, tag_id: tag.id }
      });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      console.log(err);
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const updateTags = (article, tags, posfix = "") => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    try {
      const responseData = await axios.post(process.env.REACT_APP_BACKEND_URL + "/articles/updateTags", {
        article_id: article.id,
        tags: tags
      });

      dispatch({
        type: "UPDATE_TAGS" + (posfix ? posfix : ""),
        payload: { article_id: article.id, tags: tags }
      });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      console.log(err);
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const addTagTriggers = (tag, triggers) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    try {
      const responseData = await axios.post(process.env.REACT_APP_BACKEND_URL + "/articles/addTagTriggers", {
        tag,
        triggers
      });

      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      console.log(err);
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};

export const addTierTriggers = (tier, triggers) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_ARTICLES", payload: true });
    try {
      const responseData = await axios.post(process.env.REACT_APP_BACKEND_URL + "/articles/addTierTriggers", {
        tier,
        triggers
      });

      dispatch({ type: "LOADING_ARTICLES", payload: false });
    } catch (err) {
      console.log(err);
      dispatch({ type: "ERROR_ARTICLES", payload: err });
      dispatch({ type: "LOADING_ARTICLES", payload: false });
    }
  };
};
