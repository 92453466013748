import React, { useEffect, useState, useContext, Fragment } from "react";
import { connect } from "react-redux";
import Calendar from "react-calendar";
import { Helmet } from "react-helmet";
import {
  getLanguages,
  getSourceTypes,
  getSourceStatuses,
  getJurisdictions,
  getAlertSubaccounts,
  getTags
} from "../actions/related";

import { AuthContext } from "../shared/context/auth-context";

import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import EnhancedTable from "../shared/components/UIElements/EnhancedTable";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Icon,
  Breadcrumbs,
  Link,
  TextField,
  Grid,
  Dialog,
  Input,
  Typography,
  Card,
  Snackbar,
  IconButton,
  Button
} from "@material-ui/core";
import { Alert, AlertTitle, Pagination } from "@material-ui/lab";
import { useConfirm } from "material-ui-confirm";

import ProgressBar from "../shared/components/UIElements/ProgressBar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import { getSources } from "../actions/sources";
import InboxArticle from "./InboxArticle";

import { utc } from "moment-timezone";

import InboxCard from "./InboxCard";
import TriggerForm from "./TriggerForm";

import { Box } from "victory";
import {
  getInboxArticles,
  setBackToInbox,
  reassignMother,
  updateJurs,
  updateTags,
  addTagTriggers,
  setCurrentArticle,
  clearCurrentArticle,
  addTierTriggers,
  setFilters,
  clearErrorArticles
} from "../actions/articles";

import ReactPaginate from "react-paginate";
import LazyLoad from "react-lazyload";
import InboxFilters from "./InboxFilters";
import AddJur from "./AddJur";
import AddTag from "./AddTag";

import ArticleForm from "../articles/ArticleForm";
import AddArticleBtn from "../articles/AddArticleBtn";

const Inbox = ({
  error,
  clearErrorArticles,
  sources,
  alert_subaccounts,
  getSources,
  getAlertSubaccounts,
  getSourceTypes,
  source_types,
  getJurisdictions,
  jurisdictions,
  getTags,
  tags,
  languages,
  getLanguages,
  getInboxArticles,
  inbox_articles,
  current,
  setCurrentArticle,
  clearCurrentArticle,
  show_articles,
  loading,
  setBackToInbox,
  reassignMother,
  filter,
  updateJurs,
  updateTags,
  addTagTriggers,
  addTierTriggers,
  setFilters,
  filters
}) => {
  const [date, setDate] = useState(utc(new Date()).subtract(3, "days").toDate()); //new Date()

  const [open, setOpen] = React.useState(false);

  const [articleNewJur, setArticleNewJur] = React.useState(null);
  const [articleNewTag, setArticleNewTag] = React.useState(null);

  const [page, setPage] = React.useState(1);

  const [reassign, setReassign] = React.useState(null);
  const [reassignChildren, setReassignChildren] = React.useState([]);

  const [selectedArticle, setSelectedArticle] = React.useState({});

  const [undoDeleteArticle, setUndoDeleteArticle] = React.useState(null);

  const [undoApproveArticle, setUndoApproveArticle] = React.useState(null);

  const [newTrigger, setNewTrigger] = React.useState({});

  const [success, setSuccess] = React.useState("");

  const [multipleRemove, setMultipleRemove] = React.useState([]);

  const handleCloseReassigned = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess("");
  };

  const rowsPerPage = 30;

  const addReassignChildren = (child) => {
    setReassignChildren([...reassignChildren, child]);
  };

  const removeReassignChildren = (child_id) => {
    setReassignChildren(reassignChildren.filter((c) => c.id !== child_id));
  };

  useEffect(() => {
    if ((!sources || sources.length === 0) && !error) getSources();
    if (!source_types || !source_types.length) getSourceTypes();
    if (!jurisdictions || !jurisdictions.length) getJurisdictions();
    if (!languages || !languages.length) getLanguages();
    if (!tags || !tags.length) getTags();

    if (!alert_subaccounts || alert_subaccounts.length === 0) getAlertSubaccounts();

    getInboxArticles(date.toISOString().split("T")[0]).then(() => setFilters({ ...filters }));
  }, [date]);

  const columns = [
    {
      id: "publisher",
      numeric: false,
      disablePadding: false,
      label: "Publisher"
    },
    {
      id: "original_header",
      numeric: false,
      disablePadding: false,
      label: "Header"
    },
    {
      id: "translated_header",
      numeric: false,
      disablePadding: false,
      label: "Translated Header"
    },
    {
      id: "original_url",
      numeric: false,
      disablePadding: false,
      label: "URL",
      noSelect: true,
      transform: function (row) {
        return (
          <a href={row.original_url} target="_blank">
            {row.original_url.slice(0, 30) + "..."}
          </a>
        );
      }
    },
    {
      id: "extracted_data",
      numeric: false,
      disablePadding: false,
      label: "Extract",
      noSelect: true,
      transform: function (row) {
        if (row.article_content && row.article_content.content)
          return <a onClick={(e) => handleClickOpen(row)}>EXTRACTED</a>;
      }
    },
    {
      id: "source_id",
      numeric: false,
      disablePadding: false,
      label: "Source",
      noSelect: true,
      transform: function (row) {
        if (!row.source_id) return "N/A";
        else return sources.find((s) => s.id === row.source_id).name;
      }
    }
  ];

  const handleClickOpen = (article) => {
    setSelectedArticle(article);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedArticle({});
  };
  const classes = useStyles();

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Inbox - ASPU</title>
      </Helmet>
      {loading && <ProgressBar />}
      <ErrorSnack error={error} clearError={clearErrorArticles} />
      <ArticleForm
        current={current}
        setSuccess={setSuccess}
        setCurrentArticle={setCurrentArticle}
        clearCurrentArticle={clearCurrentArticle}
      />
      <InboxArticle selectedArticle={selectedArticle} open={open || false} onClose={handleClose} />
      <TriggerForm newTrigger={newTrigger} setNewTrigger={setNewTrigger} addTierTriggers={addTierTriggers} />
      {jurisdictions && jurisdictions.length > 0 && (
        <AddJur
          article={articleNewJur}
          jurisdictions={jurisdictions}
          setArticleNewJur={setArticleNewJur}
          updateJurs={updateJurs}
        />
      )}
      {tags && tags.length > 0 && (
        <AddTag article={articleNewTag} tags={tags} setArticleNewTag={setArticleNewTag} updateTags={updateTags} />
      )}

      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="xl" style={{ width: "90%" }}>
          {reassign && (
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              style={{ top: "64px" }}
              ContentProps={{
                classes: {
                  root: classes.snack
                }
              }}
              open={reassign !== null}
              onClose={handleClose}
              message={
                'Assigning children to "' +
                (reassign.source ? reassign.source.name + "'s\"" : "") +
                ' article "' +
                (reassign.translated_header || reassign.original_header).slice(0, 25) +
                '..."'
              }
              action={
                <React.Fragment>
                  {reassignChildren.length > 0 && (
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={(e) => {
                        const justArticle = {
                          ...reassign
                        };
                        delete justArticle.articles;
                        reassignMother(justArticle, reassignChildren);
                        setReassign(null);
                        setReassignChildren([]);
                        setSuccess("Children reassigned successfully");
                      }}
                    >
                      REASSIGN {reassignChildren.length} ARTICLES
                    </Button>
                  )}
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => {
                      setReassign(null);
                      setReassignChildren([]);
                    }}
                  >
                    <Icon fontSize="small">close</Icon>
                  </IconButton>
                </React.Fragment>
              }
            />
          )}
          <Snackbar
            open={!loading && success ? true : false}
            autoHideDuration={3000}
            onClose={handleCloseReassigned}
            style={{ top: "64px" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <MuiAlert elevation={6} variant="filled" onClose={handleCloseReassigned} severity="success">
              {success.msg ? success.msg : success}
            </MuiAlert>
          </Snackbar>
          {source_types && source_types.length > 0 && (
            <InboxFilters
              date={date}
              setDate={setDate}
              source_types={source_types}
              setPage={setPage}
              languages={languages}
              loading={loading}
            />
          )}
          <br />
          <br />
          <div className={classes.marginAutoContainer}>
            <div className={classes.marginAutoItem}>
              <Pagination
                count={Math.ceil(show_articles.length / rowsPerPage)}
                page={page}
                onChange={(e, value) => setPage(value)}
                boundaryCount={20}
              />
            </div>
          </div>
          {!loading && inbox_articles.length === 0 && (
            <center>
              <iframe
                src="https://giphy.com/embed/3oz8xAFtqoOUUrsh7W"
                width="480"
                height="320"
                frameBorder="0"
                className="giphy-embed"
                allowFullScreen
              />
            </center>
          )}
          <Grid container>
            {show_articles.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((article, index) => (
              <Grid item md={12} padding={120} key={index}>
                <InboxCard
                  key={article.id}
                  article={article}
                  sources={sources}
                  inboxPosition={index}
                  source_types={source_types}
                  jurisdictions={jurisdictions}
                  languages={languages}
                  reassign={reassign}
                  setReassign={setReassign}
                  addReassignChildren={addReassignChildren}
                  reassignChildren={reassignChildren}
                  toReassign={reassignChildren.map((c) => c.id).indexOf(article.id) !== -1}
                  removeReassignChildren={removeReassignChildren}
                  setUndoDeleteArticle={setUndoDeleteArticle}
                  setUndoApproveArticle={setUndoApproveArticle}
                  setSuccess={setSuccess}
                  success={success}
                  mother={article}
                  setArticleNewJur={setArticleNewJur}
                  setArticleNewTag={setArticleNewTag}
                  setCurrentArticle={setCurrentArticle}
                  setNewTrigger={setNewTrigger}
                  multipleRemove={multipleRemove}
                  setMultipleRemove={setMultipleRemove}
                />
              </Grid>
            ))}
          </Grid>
          <div className={classes.marginAutoContainer}>
            <div className={classes.marginAutoItem}>
              <Pagination
                count={Math.ceil(show_articles.length / rowsPerPage)}
                page={page}
                onChange={(e, value) => {
                  setPage(value);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                  });
                }}
                boundaryCount={20}
              />
            </div>
          </div>
          {undoDeleteArticle && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              open={!loading && undoDeleteArticle !== null}
              autoHideDuration={6000}
              onClose={(e, reason) => {
                if (reason === "timeout") setUndoDeleteArticle(null);
              }}
              message={
                (undoDeleteArticle.source ? undoDeleteArticle.source.name + "'s \"" : '"') +
                (undoDeleteArticle.translated_header || undoDeleteArticle.original_header).slice(0, 25) +
                '..." ' +
                "article moved to bin"
              }
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      setBackToInbox(undoDeleteArticle);
                      setUndoDeleteArticle(null);
                    }}
                  >
                    UNDO
                  </Button>
                  <Button style={{ color: "yellow" }} size="small" onClick={(e) => setUndoDeleteArticle(null)}>
                    DISABLE SOURCE
                  </Button>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => setUndoDeleteArticle(null)}
                  >
                    <Icon fontSize="small">close</Icon>
                  </IconButton>
                </React.Fragment>
              }
            />
          )}
          {undoApproveArticle && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              open={!loading && undoApproveArticle !== null}
              autoHideDuration={6000}
              onClose={(e, reason) => {
                if (reason === "timeout") setUndoApproveArticle(null);
              }}
              message={
                (undoApproveArticle.source ? undoApproveArticle.source.name + "'s \"" : '"') +
                (undoApproveArticle.translated_header || undoApproveArticle.original_header).slice(0, 25) +
                '..." ' +
                "article approved"
              }
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      setBackToInbox(undoApproveArticle);
                      setUndoApproveArticle(null);
                    }}
                  >
                    UNDO
                  </Button>

                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => setUndoApproveArticle(null)}
                  >
                    <Icon fontSize="small">close</Icon>
                  </IconButton>
                </React.Fragment>
              }
            />
          )}
          <AddArticleBtn />
        </Container>
      </React.Fragment>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableHead: {
    backgroundColor: "#eee"
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px"
  },
  modalPaper: {
    width: "10%"
  },
  snack: { backgroundColor: "#878bff" },
  marginAutoContainer: {
    width: "100%",
    height: "auto",
    display: "flex",
    marginBottom: "20px",
    justifyContent: "flex-end"
  },
  marginAutoItem: {
    //margin: "auto"
  }
});

const mapStateToProps = (state) => ({
  sources: state.sources.all,
  alert_subaccounts: state.related.alert_subaccounts,
  source_types: state.related.source_types,
  jurisdictions: state.related.jurisdictions,
  tags: state.related.tags,
  languages: state.related.languages,
  inbox_articles: state.articles.inbox,
  filter: state.articles.filter,
  loading: state.articles.loading,
  show_articles: state.articles.filter.active ? state.articles.filter.result : state.articles.inbox,
  current: state.articles.current,
  filters: state.articles.filter,
  error: state.articles.error
});

const mapDispatchToProps = (dispatch) => ({
  getSources: () => dispatch(getSources()),
  getAlertSubaccounts: () => dispatch(getAlertSubaccounts()),
  getSourceTypes: () => dispatch(getSourceTypes()),
  getJurisdictions: () => dispatch(getJurisdictions()),
  getTags: () => dispatch(getTags()),
  getLanguages: () => dispatch(getLanguages()),
  getInboxArticles: (date) => dispatch(getInboxArticles(date)),
  setBackToInbox: (article) => dispatch(setBackToInbox(article)),
  reassignMother: (mother, children) => dispatch(reassignMother(mother, children)),
  updateJurs: (art, jurs) => dispatch(updateJurs(art, jurs)),
  updateTags: (art, tags) => dispatch(updateTags(art, tags)),
  addTagTriggers: (tag, triggers) => dispatch(addTagTriggers(tag, triggers)),
  addTierTriggers: (tier, triggers) => dispatch(addTierTriggers(tier, triggers)),
  setCurrentArticle: (art) => dispatch(setCurrentArticle(art)),
  clearCurrentArticle: () => dispatch(clearCurrentArticle()),
  clearErrorArticles: () => dispatch(clearErrorArticles()),
  setFilters: (filters) => dispatch(setFilters(filters))
});
export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
