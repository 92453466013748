import { Dialog, DialogContent, DialogTitle, Grid, Icon, IconButton, Paper } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import { utc } from "moment-timezone";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  approveArticle,
  assignToMotherInboxDashboard,
  convertToMother,
  deleteArticle,
  removeDashMother,
  removeFromMother,
  removeMultipleFromMother
} from "../actions/articles";
import DashParentConfirm from "./DashParentConfirm";
import "./InboxCard.css";
import JurButtons from "./JurButtons";
import TagButtons from "./TagButtons";
import { AutoSizer, List } from "react-virtualized";
import useWindowDimensions from "../hooks/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
    paddingBottom: "0px",
    borderRadius: "5px"
  },
  rootR: {
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
    borderRadius: "5px",
    paddingBottom: "0px",
    backgroundColor: "#b7cfff"
  },
  rootM: {
    width: "100%",
    marginBottom: "20px",
    borderRadius: "5px",
    backgroundColor: "rgb(255, 252, 148)"
  },
  content: {
    paddingTop: "10px",
    "&:last-child": {
      paddingBottom: "10px"
    }
  },
  valignCenter: {
    display: "inline-flex",
    verticalAlign: "middle",
    alignItems: "center"
  },
  actions: {
    display: "flex",
    backgroundColor: "#fafafa"
  },
  expand: {
    marginLeft: "auto",
    fontSize: "1.5rem",
    minWidth: "50px"
  },
  header: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    },
    color: "navy",
    fontWeight: "bold"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  paper: {
    backgroundColor: theme.palette.background.paper
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

function InboxCard({
  error,
  article,
  sources,
  source_types,
  jurisdictions,
  languages,
  inboxPosition,
  loading,
  reassign,
  setReassign,
  addReassignChildren,
  toReassign,
  reassignChildren,
  removeReassignChildren,
  removeMultipleFromMother,
  deleteArticle,
  setUndoDeleteArticle,
  approveArticle,
  setUndoApproveArticle,
  removeFromMother,
  convertToMother,
  setSuccess,
  success,
  motherPosition,
  setArticleNewJur,
  setArticleNewTag,
  setCurrentArticle,
  setNewTrigger,
  multipleRemove,
  setMultipleRemove,
  mother,
  posfix,
  users,
  inbxHack,
  assignToMotherInboxDashboard,
  removeDashMother
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [webview, setWebview] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteArticleJur, setDeleteArticleJur] = React.useState(null);
  const [deleteArticleTag, setDeleteArticleTag] = React.useState(null);
  const [addArticleJur, setAddArticleJur] = React.useState(null);
  const [timedFn, setTimedFn] = React.useState(() => {});
  const [selectedText, setSelectedText] = React.useState("");

  const [dashParentOpen, setDashParentOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const listRef = useRef();

  useEffect(() => {
    if (
      success &&
      success.data &&
      ((success.data.action === "newmother" && success.data.article.id === article.id) ||
        (success.data.action === "editarticle" && success.data.article.parent_article_id === article.id) ||
        (success.data.action === "removefrommother" && success.data.parent_article_id === article.id))
    ) {
      setExpanded(true);
    }
  }, [success]);

  useEffect(() => {
    if (!article.parent_article_id)
      //Hack for react-virtualized
      setMultipleRemove([]);
  }, [expanded]);

  useEffect(() => {
    if (error) setUndoApproveArticle(null);
  }, [error]);

  useEffect(() => {
    if (article && article.id === 633568) {
      console.log("reloaddd!");
    }
  }, []);
  useEffect(() => {
    /*if (reassignChildren.length && listRef.current) {
      alert(listRef);
      listRef.current.forceUpdateGrid();
    }
    */
  }, [reassignChildren]);

  const emptySelectedText = () => {
    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {
      // IE?
      document.selection.empty();
    }

    setSelectedText();
  };

  const selectionAction = (event) => {
    let selText = window.getSelection().toString();

    clearTimeout(timedFn);
    setSelectedText(selText);
    //setTimedFn(setTimeout(() => emptySelectedText(), 10000));
  };

  const addTrigger = (type) => {
    let selected = selectedText;
    clearTimeout(timedFn);
    setNewTrigger({ type, text: selectedText });
    emptySelectedText();
  };

  const confirm = useConfirm();

  const columns = [
    {
      id: "publisher",
      numeric: false,
      disablePadding: false,
      label: "Publisher"
    },
    {
      id: "original_header",
      numeric: false,
      disablePadding: false,
      label: "Header"
    },
    {
      id: "translated_header",
      numeric: false,
      disablePadding: false,
      label: "Translated Header"
    },
    {
      id: "original_url",
      numeric: false,
      disablePadding: false,
      label: "URL",
      noSelect: true,
      transform: function (row) {
        return (
          <a href={row.original_url} target="_blank">
            {row.original_url.slice(0, 30) + "..."}
          </a>
        );
      }
    },
    {
      id: "source_id",
      numeric: false,
      disablePadding: false,
      label: "Source",
      noSelect: true,
      transform: function (row) {
        if (!row.source_id) return "N/A";
        else return sources.find((s) => s.id === row.source_id).name;
      }
    }
  ];
  let st;
  let jur;
  if (article.source) {
    st = source_types.find((st) => st.id === article.source.source_type_id).code;

    jur = jurisdictions.find((jur) => jur.id === article.source.jurisdiction_id);
  }

  let articleJurisdictions;
  let global_jur = null;
  let con_jur = null;
  if (article.jurisdictions && article.jurisdictions.length) {
    if (article.jurisdictions.length > 3) global_jur = jurisdictions.find((jur) => jur.id === "REG-4");
  } else {
    con_jur = jur;
  }

  let lang = article.g_detected_language ? article.g_detected_language : article.detected_language;

  if (lang) {
    let langTemp = languages.find((l) => l.id === lang);
    if (langTemp) lang = langTemp.name;
  }

  const isChosenReassign = reassign && reassign.id === article.id;

  let username;
  let user;
  if (users) {
    user = users.find((u) => u.id === article.user_id);
    if (user) username = user.name.split(" ")[0];
    //user.name.split(" ")[0][0] + ". " + user.name.split(" ")[1][0] + ". ";
    else username = "N/A";
  }
  const { height, width } = useWindowDimensions();

  const assignToMother = (convert) => {
    assignToMotherInboxDashboard(article.dash_parent_article_id, article.id, convert);
  };
  return (
    <Fragment>
      {article.article_content && article.article_content.content && (
        <Dialog
          contentStyle={{
            width: "80%",
            maxWidth: "none"
          }}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open || false}
          onClose={handleClose}
          closeAfterTransition
          maxWidth="lg"
          title={
            <div>
              <img
                src="https://d30y9cdsu7xlg0.cloudfront.net/png/53504-200.png"
                style={{
                  cursor: "pointer",
                  float: "right",
                  marginTop: "5px",
                  width: "20px"
                }}
              />
            </div>
          }
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <h2>{article.article_content.title}</h2>
            <IconButton onClick={handleClose}>
              <Icon>close</Icon>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Fade in={open}>
              <div className={classes.paper}>
                <p id="transition-modal-description" style={{ whiteSpace: "pre-wrap" }}>
                  {article.article_content.content.replace(/<br \/>/g, "\n")}
                </p>
              </div>
            </Fade>
          </DialogContent>
        </Dialog>
      )}

      <Card
        className={
          !toReassign
            ? isChosenReassign
              ? classes.rootM
              : multipleRemove.length && multipleRemove.find((a) => a.id === article.id)
              ? classes.rootR
              : classes.root
            : classes.rootR
        }
        style={{ ...(article.parent_article_id && { minHeight: "120px" }) }}
        onMouseUp={selectionAction}
      >
        <CardContent className={classes.content} style={{ position: "relative" }}>
          {posfix === "_DASHBOARD" && article.articles && (
            <span
              style={{
                position: "absolute",
                right: "2px",
                bottom: "1px",
                fontSize: "10px"
              }}
            >
              {username}
            </span>
          )}
          <Grid container spacing={3} className={classes.valignCenter}>
            <Grid item md={2} border={1}>
              <Grid
                container
                spacing={2}
                className={classes.valignCenter}
                onMouseOver={(e) => setAddArticleJur(article)}
                onMouseOut={(e) => setAddArticleJur(null)}
              >
                <Grid item md={3} style={{ textAlign: "center" }}>
                  <Typography gutterBottom variant="body2" component="h6" style={{ textAlign: "center" }}>
                    <time datetime="2014-09-20" className="icon">
                      <strong>{utc(article.digest_date).format("MMM")}</strong>
                      <span>{utc(article.digest_date).format("DD")}</span>
                    </time>
                    {false && posfix === "_DASHBOARD" && article.articles && (
                      <Typography gutterBottom variant="body3">
                        {username}
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{
                    textAlign: "center",
                    ...(posfix === "_DASHBOARD" && !article.articles && { visibility: "hidden" })
                  }}
                >
                  {global_jur && (
                    <Fragment>
                      <img width="32px" src={"/img/country_flags/" + global_jur.country_code + "@3x.png"} />

                      <br />
                      <span style={{ fontWeight: "bold" }}>{global_jur.country_code}</span>
                    </Fragment>
                  )}

                  {!global_jur && !con_jur && article.jurisdictions && article.jurisdictions.length > 1 && (
                    <Fragment>
                      <div
                        style={{ position: "relative" }}
                        onMouseOver={(e) => setDeleteArticleJur(article.jurisdictions[0].id)}
                        onMouseOut={(e) => setDeleteArticleJur(null)}
                      >
                        <img
                          width="32px"
                          src={"/img/country_flags/" + article.jurisdictions[0].jurisdiction.country_code + "@3x.png"}
                        />
                        {!article.parent_article_id && (
                          <JurButtons
                            article={article}
                            posfix={posfix}
                            jur={article.jurisdictions[0].jurisdiction}
                            show={article.jurisdictions[0].id === deleteArticleJur}
                            setArticleNewJur={setArticleNewJur}
                          />
                        )}
                      </div>
                      <span style={{ fontWeight: "bold" }}>{article.jurisdictions[0].jurisdiction.country_code}</span>
                    </Fragment>
                  )}
                </Grid>
                <Grid
                  item
                  md={global_jur ? 6 : 3}
                  style={{
                    textAlign: "center",
                    ...(posfix === "_DASHBOARD" && !article.articles && { visibility: "hidden" })
                  }}
                >
                  {global_jur && (
                    <Grid container>
                      {article.jurisdictions &&
                        article.jurisdictions.map((art, index) => (
                          <Grid item md={4} style={{ textAlign: "center" }}>
                            <div
                              style={{ position: "relative" }}
                              onMouseOver={(e) => setDeleteArticleJur(article.jurisdictions[index].id)}
                              onMouseOut={(e) => setDeleteArticleJur(null)}
                            >
                              <img
                                width="32px"
                                src={
                                  "/img/country_flags/" +
                                  article.jurisdictions[index].jurisdiction.country_code +
                                  "@3x.png"
                                }
                              />
                              {!article.parent_article_id && (
                                <JurButtons
                                  article={article}
                                  posfix={posfix}
                                  jur={article.jurisdictions[index].jurisdiction}
                                  show={article.jurisdictions[index].id === deleteArticleJur}
                                  setArticleNewJur={setArticleNewJur}
                                />
                              )}
                            </div>
                            <span style={{ fontWeight: "bold" }}>
                              {article.jurisdictions[index].jurisdiction.country_code}
                            </span>
                          </Grid>
                        ))}
                    </Grid>
                  )}
                  {con_jur && (
                    <Fragment>
                      <div
                        style={{ position: "relative" }}
                        onMouseOver={(e) => setDeleteArticleJur(con_jur.id)}
                        onMouseOut={(e) => setDeleteArticleJur(null)}
                      >
                        <img width="32px" src={"/img/country_flags/" + con_jur.country_code + "@3x.png"} />
                        {!article.parent_article_id && (
                          <JurButtons
                            article={article}
                            posfix={posfix}
                            jur={con_jur}
                            show={con_jur.id === deleteArticleJur}
                            setArticleNewJur={setArticleNewJur}
                            con={true}
                          />
                        )}
                        <br />
                        <span style={{ color: "darkred", fontWeight: "bold" }}>{con_jur.country_code}</span>
                      </div>
                    </Fragment>
                  )}
                  {!global_jur && !con_jur && article.jurisdictions && article.jurisdictions.length === 1 && (
                    <Fragment>
                      <div
                        style={{ position: "relative" }}
                        onMouseOver={(e) => setDeleteArticleJur(article.jurisdictions[0].id)}
                        onMouseOut={(e) => setDeleteArticleJur(null)}
                      >
                        <img
                          width="32px"
                          src={"/img/country_flags/" + article.jurisdictions[0].jurisdiction.country_code + "@3x.png"}
                        />
                        {!article.parent_article_id && (
                          <JurButtons
                            article={article}
                            posfix={posfix}
                            jur={article.jurisdictions[0].jurisdiction}
                            show={article.jurisdictions[0].id === deleteArticleJur}
                            setArticleNewJur={setArticleNewJur}
                          />
                        )}
                      </div>
                      <span style={{ fontWeight: "bold" }}>{article.jurisdictions[0].jurisdiction.country_code}</span>
                    </Fragment>
                  )}
                  {!global_jur && !con_jur && article.jurisdictions[1] && (
                    <Fragment>
                      <div
                        style={{ position: "relative" }}
                        onMouseOver={(e) => setDeleteArticleJur(article.jurisdictions[1].id)}
                        onMouseOut={(e) => setDeleteArticleJur(null)}
                      >
                        <img
                          width="32px"
                          src={"/img/country_flags/" + article.jurisdictions[1].jurisdiction.country_code + "@3x.png"}
                        />
                        {!article.parent_article_id && (
                          <JurButtons
                            article={article}
                            posfix={posfix}
                            jur={article.jurisdictions[1].jurisdiction}
                            show={article.jurisdictions[1].id === deleteArticleJur}
                            setArticleNewJur={setArticleNewJur}
                          />
                        )}
                      </div>
                      <span style={{ fontWeight: "bold" }}>{article.jurisdictions[1].jurisdiction.country_code}</span>
                    </Fragment>
                  )}
                </Grid>
                {!global_jur && (
                  <Grid
                    item
                    md={3}
                    style={{
                      textAlign: "center",
                      ...(posfix === "_DASHBOARD" && !article.articles && { visibility: "hidden" })
                    }}
                  >
                    {" "}
                    {!global_jur && !con_jur && article.jurisdictions[2] && (
                      <Fragment>
                        <div
                          style={{ position: "relative" }}
                          onMouseOver={(e) => setDeleteArticleJur(article.jurisdictions[2].id)}
                          onMouseOut={(e) => setDeleteArticleJur(null)}
                        >
                          <img
                            width="32px"
                            src={"/img/country_flags/" + article.jurisdictions[2].jurisdiction.country_code + "@3x.png"}
                          />
                          {!article.parent_article_id && (
                            <JurButtons
                              article={article}
                              posfix={posfix}
                              jur={article.jurisdictions[2].jurisdiction}
                              show={article.jurisdictions[2].id === deleteArticleJur}
                              setArticleNewJur={setArticleNewJur}
                            />
                          )}
                        </div>
                        <span style={{ fontWeight: "bold" }}>{article.jurisdictions[2].jurisdiction.country_code}</span>
                      </Fragment>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item md={8}>
              <Grid container spacing={0} className={classes.valignCenter}>
                <Grid item md={1} style={{ textAlign: "center", maxWidth: "48px" }}>
                  <Fragment>
                    {" "}
                    {article.article_content && article.article_content.content && (
                      <IconButton size="small" onClick={(e) => setOpen(true)}>
                        <Icon>subject</Icon>
                      </IconButton>
                    )}
                  </Fragment>
                </Grid>
                <Grid item md={11}>
                  <Grid container spacing={3} className={classes.valignCenter}>
                    <Grid item md={7}>
                      <a
                        className={classes.header}
                        onClick={(e) => {
                          if (article.detected_language !== "en")
                            window.open(
                              "https://translate.google.com/translate?js=n&sl=auto&tl=en&u=" + article.original_url,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          else {
                            window.open(article.original_url, "_blank", "noopener,noreferrer");
                          }
                        }}
                      >
                        <Typography gutterBottom variant="body2" component="span">
                          {article.translated_header || article.original_header}{" "}
                        </Typography>
                      </a>
                      <Typography gutterBottom variant="body3" style={{ fontStyle: "italic" }} component="h6">
                        {article.translated_header && (
                          <a
                            className={classes.header}
                            onClick={(e) => {
                              window.open(article.original_url, "_blank", "noopener,noreferrer");
                            }}
                          >
                            {article.original_header}
                          </a>
                        )}
                      </Typography>
                      <Typography gutterBottom style={{ fontSize: "0.6rem" }} component="h6">
                        {article.original_description}
                      </Typography>
                      {selectedText && (
                        <Fragment>
                          {false && (
                            <Fragment>
                              <Button color="primary" size="small" onClick={(e) => addTrigger("jur")}>
                                + JUR TRIGGER
                              </Button>
                              <Button style={{ color: "black" }} size="small" onClick={(e) => addTrigger("con")}>
                                + CON TRIGGER
                              </Button>
                              <Button color="secondary" size="small" onClick={(e) => addTrigger("tag")}>
                                + TAG TRIGGER
                              </Button>
                            </Fragment>
                          )}

                          <Button style={{ color: "green" }} size="small" onClick={(e) => addTrigger("tier")}>
                            + TIER TRIGGER
                          </Button>
                        </Fragment>
                      )}
                    </Grid>
                    <Grid item md={5}>
                      <Grid container spacing={1}>
                        <Grid item md={5}>
                          {article.source ? (
                            <Fragment>
                              <Typography gutterBottom variant="body2" style={{ fontStyle: "italic" }} component="span">
                                {article.source.name}
                              </Typography>
                              <Typography gutterBottom variant="body3" component="h6">
                                {st} -{" "}
                                <img
                                  width="14px"
                                  style={{ verticalAlign: "middle" }}
                                  src={"/img/country_flags/" + jur.country_code + ".png"}
                                />{" "}
                                - {jur.name}
                              </Typography>
                              {article.source.media_types && article.source.media_types.length > 0 && (
                                <Typography gutterBottom variant="body3" component="h6">
                                  {article.source.media_types.map((mt) => mt.name).join(" - ")}
                                </Typography>
                              )}
                              {article.source.ilga_m > 0 && (
                                <Typography gutterBottom variant="body3" component="h6">
                                  {article.source.ilga_m === 1 ? "ILGA Member" : "NON-ILGA Member"}
                                </Typography>
                              )}
                            </Fragment>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid
                          item
                          md={7}
                          style={{
                            ...(posfix === "_DASHBOARD" && !article.articles && { visibility: "hidden" })
                          }}
                        >
                          <Grid container>
                            <Grid item md={12}>
                              <Typography gutterBottom variant="body2" component="h6">
                                {article.tags && article.tags.length > 0 ? (
                                  article.tags.map((t) => (
                                    <Fragment>
                                      <div
                                        style={{
                                          border: "1px solid lightgray",
                                          backgroundColor: "#c5dce1",
                                          paddingTop: "2px",
                                          paddingBottom: "2px",
                                          fontSize: "0.775rem",
                                          margin: "1px",

                                          position: "relative",
                                          display: "inline-block",
                                          minWidth: "50px",
                                          textAlign: "center"
                                        }}
                                        onMouseOver={(e) => setDeleteArticleTag(t.id)}
                                        onMouseOut={(e) => setDeleteArticleTag(null)}
                                      >
                                        <TagButtons
                                          posfix={posfix}
                                          article={article}
                                          tag={t.tag}
                                          show={t.id === deleteArticleTag}
                                          setArticleNewTag={setArticleNewTag}
                                        />
                                        <span style={{ padding: "4px" }}>{t.tag.tag.toUpperCase()}</span>
                                      </div>
                                    </Fragment>
                                  ))
                                ) : (
                                  <Fragment>
                                    <div
                                      style={{
                                        margin: "1px",

                                        position: "relative",
                                        display: "inline-block",
                                        minWidth: "50px",
                                        textAlign: "center"
                                      }}
                                    >
                                      <TagButtons
                                        posfix={posfix}
                                        article={article}
                                        tag={null}
                                        show={true}
                                        setArticleNewTag={setArticleNewTag}
                                      />
                                    </div>
                                  </Fragment>
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={1}>
              <Grid container>
                <Grid item md={6} style={{ marginTop: "8px" }}>
                  <Typography gutterBottom variant="body2" component="span">
                    {!sources.find((s) => s.id === article.source_id) ||
                    sources.find((s) => s.id === article.source_id).languages.find((l) => l.name === lang) ? (
                      lang
                    ) : (
                      <span style={{ fontWeight: "bold", color: "red" }}>{lang}</span>
                    )}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  {article.articles && (
                    <Typography gutterBottom variant="body2" component="h6">
                      <div className={classes.valignCenter}>
                        <Button style={{ color: "gray" }} onClick={(e) => setExpanded(!expanded)}>
                          <Icon>list_alt</Icon> {article.articles.length}
                        </Button>
                      </div>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={1}>
              {!reassign && article.articles && (
                <Button
                  className={classes.expand}
                  style={{ color: "darkblue" }}
                  onClick={(e) => setReassign(article)}
                  title="Assign Children"
                >
                  <Icon fontSize="inherit">device_hub_rounded</Icon>
                </Button>
              )}

              {article.dash_mother && article.dash_parent_article_id && article.articles && (
                <>
                  <Button style={{ color: "darkgreen", marginLeft: "-8px" }} onClick={(e) => setDashParentOpen(true)}>
                    <Icon>account_balance_wallet</Icon>
                  </Button>
                  <DashParentConfirm
                    open={dashParentOpen}
                    setOpen={setDashParentOpen}
                    title="Potential mother detected in dashboard"
                    assignToMother={assignToMother}
                    article={article.dash_mother}
                    jurisdictions={jurisdictions}
                    removeDashMother={removeDashMother}
                    child={article}
                  />
                </>
              )}

              {!article.dash_parent_article_id &&
                article.articles &&
                !isChosenReassign &&
                !toReassign &&
                (posfix !== "_DASHBOARD" || (posfix === "_DASHBOARD" && inbxHack)) && (
                  <Button
                    size="small"
                    style={{
                      color: "darkgreen"
                    }}
                    className={classes.expand}
                    title="Approve"
                    onClick={(e) => {
                      if (article.tags.length > 6)
                        confirm({
                          cancellationText: "",
                          title: "Too many tags",
                          description: "Maximum tags allowed: 6. Please remove some tags before proceeding"
                        })
                          .then(() => {})
                          .catch(() => {});
                      else {
                        setUndoApproveArticle(null);
                        if (inbxHack) {
                          approveArticle({ ...article, inbxHack: true });
                          setUndoApproveArticle({
                            ...article,
                            inbxHack: true,
                            parent_article_id_old: article.parent_article_id
                          });
                        } else {
                          approveArticle(article);
                          setUndoApproveArticle({
                            ...article,
                            parent_article_id_old: article.parent_article_id
                          });
                        }
                      }
                    }}
                  >
                    <Icon fontSize="inherit">check_box_rounded</Icon>
                  </Button>
                )}

              {reassign && !isChosenReassign && !toReassign && (
                <Button className={classes.expand} onClick={(e) => addReassignChildren(article)}>
                  <Icon fontSize="inherit">merge_type</Icon>
                </Button>
              )}
              {reassign && !isChosenReassign && toReassign && (
                <Button className={classes.expand} onClick={(e) => removeReassignChildren(article.id)}>
                  <Icon fontSize="inherit">close</Icon>
                </Button>
              )}
              {!isChosenReassign && !toReassign && article.articles && (
                <Fragment>
                  <Button
                    size="small"
                    style={{ color: "darkgrey" }}
                    color="primary"
                    className={classes.expand}
                    title="More"
                    onClick={(event) => {
                      setCurrentArticle(article);
                      setAnchorEl(null);
                    }}
                  >
                    <Icon style={{ color: "darkgrey" }} fontSize="inherit">
                      build_rounded
                    </Icon>
                  </Button>
                </Fragment>
              )}
              {!isChosenReassign && !toReassign && !article.articles && (
                <Fragment>
                  <Button
                    size="small"
                    style={{
                      color: "darkgrey",
                      ...(multipleRemove.length ? { visibility: "hidden" } : {})
                    }}
                    color="primary"
                    className={classes.expand}
                    onClick={(e) => setCurrentArticle(article)}
                    title="Fix"
                  >
                    <Icon style={{ color: "darkgrey" }} fontSize="22px">
                      build_rounded
                    </Icon>
                  </Button>
                </Fragment>
              )}
              {!isChosenReassign && !toReassign && (
                <Fragment>
                  {article.articles || !multipleRemove.length ? (
                    <Button
                      style={{ color: "darkred" }}
                      disabled={loading}
                      size="small"
                      className={classes.expand}
                      onClick={(e) => {
                        setUndoDeleteArticle(null);
                        deleteArticle(article, posfix);
                        setUndoDeleteArticle({
                          ...article,
                          parent_article_id_old: article.parent_article_id
                        });
                      }}
                      title="Discard"
                    >
                      <Icon fontSize="inherit">delete_sweep_rounded</Icon>
                    </Button>
                  ) : (
                    <Button
                      style={{ color: "darkred" }}
                      disabled={loading}
                      size="small"
                      className={classes.expand}
                      onClick={(e) => {
                        setMultipleRemove([]);
                      }}
                      title="Cancel"
                    >
                      <Icon fontSize="inherit">close_rounded</Icon>
                    </Button>
                  )}
                </Fragment>
              )}
              {!article.articles && !toReassign && !reassign && (
                <Fragment>
                  <Button
                    size="small"
                    style={{ color: "darkorange" }}
                    className={classes.expand}
                    disabled={loading}
                    onClick={(e) => {
                      const justArticle = {
                        ...article,
                        position: motherPosition
                      };
                      delete justArticle.articles;
                      if (!e.ctrlKey && !e.metaKey && !multipleRemove.length) {
                        removeFromMother(justArticle, posfix).then(() => {
                          setSuccess({
                            msg: "Successfully removed from mother",
                            data: {
                              action: "removefrommother",
                              parent_article_id: article.parent_article_id
                            }
                          });
                        });
                      } else {
                        if (multipleRemove.find((a) => a.id === article.id)) {
                          setMultipleRemove(multipleRemove.filter((a) => a.id !== article.id));
                        } else {
                          setMultipleRemove(multipleRemove.concat([justArticle]));
                        }
                      }
                    }}
                    title="Remove from mother"
                  >
                    <Icon fontSize="inherit">layers_clear_rounded</Icon>
                  </Button>
                  {!multipleRemove.length ? (
                    <Button
                      size="small"
                      style={{ color: "darkgreen" }}
                      disabled={loading}
                      className={classes.expand}
                      onClick={(e) => {
                        const justArticle = {
                          ...article,
                          position: inboxPosition
                        };
                        delete justArticle.articles;
                        convertToMother(justArticle, posfix).then(() => {
                          setSuccess({
                            msg: "Successfully converted to mother",
                            data: {
                              action: "newmother",
                              article: justArticle
                            }
                          });
                        });
                      }}
                      title="Convert to mother"
                    >
                      <Icon fontSize="inherit">publish_rounded</Icon>
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      style={{ color: "darkgreen" }}
                      disabled={loading}
                      className={classes.expand}
                      onClick={(e) => {
                        confirm({
                          title: "Confirm remove and group",
                          description:
                            "This will remove all these entries from this mother and assign it as a group for the selected entry"
                        })
                          .then(() => {
                            removeMultipleFromMother(article, multipleRemove, posfix).then(() => {
                              setSuccess({
                                msg: "Successfully converted to mother",
                                data: {
                                  action: "newmother",
                                  article: mother
                                }
                              });
                            });
                          })
                          .catch((e) => console.log(e));
                      }}
                      title="Remove and group to this mother"
                    >
                      <Icon fontSize="inherit">check_rounded</Icon>
                    </Button>
                  )}
                </Fragment>
              )}
            </Grid>
            {article.articles && article.articles.length !== 0 && (
              <Collapse in={expanded} mountOnEnter style={{ width: "100%" }} unmountOnExit>
                <Grid item md={12}>
                  <Fragment>
                    <Paper
                      style={{
                        backgroundColor: "rgb(189, 189, 189)",
                        padding: "2px",
                        maxHeight: "800px",
                        overflow: "auto"
                      }}
                    >
                      <AutoSizer disableHeight reassignChildren={reassignChildren} data={reassignChildren.length}>
                        {({ width }) => (
                          <List
                            ref={(ref) => {
                              listRef.current = ref;
                            }}
                            reassignChildren={reassignChildren}
                            data={reassignChildren.length}
                            rowRenderer={({ index, isScrolling, key, style }) => (
                              <div key={key} style={style}>
                                <InboxCard
                                  data={reassignChildren.length}
                                  article={article.articles[index]}
                                  sources={sources}
                                  source_types={source_types}
                                  jurisdictions={jurisdictions}
                                  languages={languages}
                                  reassign={reassign}
                                  setReassign={setReassign}
                                  addReassignChildren={addReassignChildren}
                                  reassignChildren={reassignChildren}
                                  toReassign={
                                    reassignChildren.map((c) => c.id).indexOf(article.articles[index].id) !== -1
                                  }
                                  removeReassignChildren={removeReassignChildren}
                                  setUndoDeleteArticle={setUndoDeleteArticle}
                                  deleteArticle={deleteArticle}
                                  setUndoApproveArticle={setUndoApproveArticle}
                                  approveArticle={approveArticle}
                                  removeFromMother={removeFromMother}
                                  inboxPosition={inboxPosition}
                                  motherPosition={article.position || inboxPosition}
                                  convertToMother={convertToMother}
                                  setSuccess={setSuccess}
                                  setArticleNewJur={setArticleNewJur}
                                  setArticleNewTag={setArticleNewTag}
                                  setCurrentArticle={setCurrentArticle}
                                  setNewTrigger={setNewTrigger}
                                  multipleRemove={multipleRemove}
                                  setMultipleRemove={setMultipleRemove}
                                  removeMultipleFromMother={removeMultipleFromMother}
                                  mother={mother}
                                  posfix={posfix}
                                />
                              </div>
                            )}
                            height={
                              article.articles.length > 4 ? 600 : article.articles.length * (width < 1300 ? 180 : 135)
                            }
                            rowCount={article.articles.length}
                            rowHeight={width < 1300 ? 180 : 130}
                            width={width}
                          />
                        )}
                      </AutoSizer>
                    </Paper>
                  </Fragment>
                </Grid>
              </Collapse>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  loading: state.articles.loading,
  error: state.articles.error
});

const mapDispatchToProps = (dispatch) => ({
  deleteArticle: (art, posfix) => dispatch(deleteArticle(art, posfix)),
  approveArticle: (art) => dispatch(approveArticle(art)),
  removeFromMother: (art, posfix) => dispatch(removeFromMother(art, posfix)),
  convertToMother: (art, posfix) => dispatch(convertToMother(art, posfix)),
  removeMultipleFromMother: (mother, arts, posfix) => dispatch(removeMultipleFromMother(mother, arts, posfix)),
  assignToMotherInboxDashboard: (mother, childe, convert) =>
    dispatch(assignToMotherInboxDashboard(mother, childe, convert)),
  removeDashMother: (art) => dispatch(removeDashMother(art))
});
export default connect(mapStateToProps, mapDispatchToProps)(InboxCard);
