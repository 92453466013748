import axios from "axios";

export const deleteIwmUser = (id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_IWM_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_IWM_USERS", payload: true });
    try {
      const responseData = await axios.delete(
        process.env.REACT_APP_BACKEND_URL + "/iwm_users/" + id
      );
      dispatch({ type: "DELETE_IWM_USER", payload: id });
      dispatch({ type: "LOADING_IWM_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_IWM_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_IWM_USERS", payload: false });
      dispatch({ type: "LOADING_IWM_USERS", payload: false });
    }
  };
};

export const setBypassIoIwmUsers = (should) => ({
  type: "SET_BYPASS_IO_IWM_USERS",
  payload: should
});

export const setOpenIwmUser = (open) => ({ type: "SET_OPEN_IWM_USER", payload: open });

export const updateIwmUser = (iwm_user) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_IWM_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_IWM_USERS", payload: true });
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/iwm_users",
        iwm_user
      );
      dispatch({ type: "UPDATE_IWM_USER", payload: iwm_user });
      dispatch({ type: "LOADING_IWM_USERS", payload: false });
      dispatch({ type: "CLEAR_CURRENT_IWM_USER" });
    } catch (err) {
      dispatch({ type: "ERROR_IWM_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_IWM_USERS", payload: false });
      dispatch({ type: "LOADING_IWM_USERS", payload: false });
    }
  };
};

export const addIwmUser = (iwm_user) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_IWM_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_IWM_USERS", payload: true });
    try {
      const responseData = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/iwm_users",
        iwm_user
      );
      dispatch({ type: "ADD_IWM_USER", payload: iwm_user });
      dispatch({ type: "LOADING_IWM_USERS", payload: false });
      dispatch({ type: "SET_OPEN_IWM_USER", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_IWM_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_IWM_USERS", payload: false });
      dispatch({ type: "LOADING_IWM_USERS", payload: false });
    }
  };
};

export const setCurrentIwmUser = (iwm_user) => ({
  type: "CURRENT_IWM_USER",
  payload: iwm_user
});

export const clearCurrentIwmUser = () => ({ type: "CLEAR_CURRENT_IWM_USER" });

export const clearErrorIwmUsers = () => ({ type: "CLEAR_ERROR_IWM_USERS" });

export const getIwmUsers = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_IWM_USERS", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/iwm_users"
      );

      dispatch({ type: "GET_IWM_USERS", payload: responseData.data.iwm_users });
      dispatch({ type: "LOADING_IWM_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_IWM_USERS", payload: err });
      dispatch({ type: "LOADING_IWM_USERS", payload: false });
    }
  };
};

export const getIwmUserLanguages = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_IWM_USERS", payload: true });
    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/iwm_users/iwm_user_details"
      );

      dispatch({
        type: "GET_IWM_USER_LANGUAGES",
        payload: responseData.data.languages
      });
      dispatch({
        type: "GET_IWM_USER_DETAILS",
        payload: responseData.data
      });
      dispatch({ type: "LOADING_IWM_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_IWM_USERS", payload: err });
      dispatch({ type: "LOADING_IWM_USERS", payload: false });
    }
  };
};

export const addConfirmed = () => ({ type: "ADD_CONFIRMED" });
export const addRejected = () => ({ type: "ADD_REJECTED" });