import DateFnsUtils from "@date-io/moment"; // choose your lib
import {
  Grid,



  Paper,


  TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Autocomplete } from "@material-ui/lab";
import {
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { utc } from "moment-timezone";
import React, { Fragment } from "react";
import {
  DateRangePicker
} from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { connect } from "react-redux";




const rainbowColors = [
  "#FF0018",
  "#FFA52C",
  "#FFFF41",
  "#008018",
  "#0000F9",
  "#86007D",
  "#FF0018",
  "#FFA52C",
  "#FFFF41",
  "#008018",
  "#0000F9",
  "#86007D"
];

const inboxTabs = ["MOU", "CSO", "DOG", "GOV", "IHR", "OTHER"];

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(20),
    flexBasis: "13.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    verticalAlign: "middle",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  filterPaper: {
    padding: "20px"
  }
}));

function DashboardFilters({
  date,
  setDate,
  source_types,
  setPage,
  articles,
  jurisdictions,
  getDashboxArticles,
  dashboard_q,
  sources,
  users,
  languages,
  tags,
  inbxHack
}) {
  const classes = useStyles();

  const [filters, setFilters] = React.useState({
    header: "",
    sou: "MOU",
    startDate: utc().subtract(40, "days"),
    endDate: utc().subtract(37, "days")
  });

  const applyFilters = async (e) => {
    getDashboxArticles(filters);
  };

  const [focusedInput, setFocusedInput] = React.useState(null);
  return (
    <Fragment>
      <Paper className={classes.filterPaper}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Typography variant="h1" component="span">
                Dashbox
              </Typography>
              <Typography
                variant="h1"
                component="span"
                style={{ float: "right" }}
              >
                {articles &&
                  articles.length > 0 &&
                  articles.length + " matching entries "}
              </Typography>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} md={3}>
            <DateRangePicker
              isOutsideRange={() => false}
              startDate={filters.startDate} // momentPropTypes.momentObj or null,
              startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
              endDate={filters.endDate} // momentPropTypes.momentObj or null,
              endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) =>
                setFilters({ ...filters, startDate, endDate })
              } // PropTypes.func.isRequired,
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
              displayFormat={() => "DD MMM YYYY"}
            />
          </Grid>
          <Grid item xs={12} md={2} style={{ textAlign: "right" }}>
            {users && users.length > 0 && (
              <Autocomplete
                id="country-select-demo"
                options={users
                  .filter((u) => u.id !== 0 && u.id !== 39 && u.id !== 40)
                  .map((u) => ({ ...u, name: u.name.split(" ")[0] }))
                  .sort((a, b) => a.name.localeCompare(b.name))}
                disableClearable={false}
                clearOnBlur={true}
                classes={{
                  option: classes.option
                }}
                multiple
                defaultValue={[]}
                autoHighlight
                size="small"
                onChange={(e, newVal) =>
                  setFilters({ ...filters, users: newVal })
                }
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <React.Fragment>{option.name}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Users"
                    variant="outlined"
                    autoFocus
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off" // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={7} style={{ textAlign: "right" }}>
            <TextField
              variant="outlined"
              size="small"
              style={{ width: "84%", backgroundColor: "white" }}
              value={filters.header}
              placeholder="Search..."
              onChange={(e) =>
                setFilters({ ...filters, header: e.target.value })
              }
            />
            <Button
              variant="contained"
              style={{ width: "10%", marginLeft: "10px", marginTop: "2px" }}
              color="secondary"
              onClick={applyFilters}
            >
              Apply
            </Button>
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
            {source_types && source_types.length > 0 && (
              <Autocomplete
                id="country-select-demo"
                options={source_types.sort((a, b) =>
                  a.name.localeCompare(b.name)
                )}
                disableClearable={false}
                clearOnBlur={true}
                classes={{
                  option: classes.option
                }}
                multiple
                defaultValue={[]}
                autoHighlight
                size="small"
                onChange={(e, newVal) =>
                  setFilters({ ...filters, source_types: newVal })
                }
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <React.Fragment>{option.name}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Source Types"
                    variant="outlined"
                    autoFocus
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off" // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
            {tags && tags.length > 0 && (
              <Autocomplete
                id="country-select-demo"
                options={tags.sort((a, b) => a.tag.localeCompare(b.tag))}
                disableClearable={false}
                clearOnBlur={true}
                classes={{
                  option: classes.option
                }}
                multiple
                defaultValue={[]}
                autoHighlight
                size="small"
                onChange={(e, newVal) =>
                  setFilters({ ...filters, tags: newVal })
                }
                getOptionLabel={(option) => option.tag}
                renderOption={(option) => (
                  <React.Fragment>{option.tag}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tags"
                    variant="outlined"
                    autoFocus
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off" // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
            {jurisdictions && jurisdictions.length > 0 && (
              <Autocomplete
                id="country-select-demo"
                options={jurisdictions.sort((a, b) =>
                  a.name.localeCompare(b.name)
                )}
                disableClearable={false}
                clearOnBlur={true}
                classes={{
                  option: classes.option
                }}
                multiple
                defaultValue={[]}
                autoHighlight
                size="small"
                onChange={(e, newVal) =>
                  setFilters({ ...filters, jurs: newVal })
                }
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <React.Fragment>
                    {true && (
                      <span>
                        <img
                          width="32px"
                          src={
                            "/img/country_flags/" +
                            option.country_code +
                            "@3x.png"
                          }
                        />
                      </span>
                    )}{" "}
                    {option.name}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Jurisdictions"
                    variant="outlined"
                    autoFocus
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off" // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
            {jurisdictions && jurisdictions.length > 0 && (
              <Autocomplete
                id="country-select-demo"
                options={jurisdictions.sort((a, b) =>
                  a.name.localeCompare(b.name)
                )}
                disableClearable={false}
                clearOnBlur={true}
                classes={{
                  option: classes.option
                }}
                multiple
                defaultValue={[]}
                autoHighlight
                size="small"
                onChange={(e, newVal) =>
                  setFilters({ ...filters, cons: newVal })
                }
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <React.Fragment>{option.name}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="CONs"
                    variant="outlined"
                    autoFocus
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off" // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
            {languages && languages.length > 0 && (
              <Autocomplete
                id="country-select-demo"
                options={languages.sort((a, b) => a.name.localeCompare(b.name))}
                disableClearable={false}
                clearOnBlur={true}
                classes={{
                  option: classes.option
                }}
                multiple
                defaultValue={[]}
                autoHighlight
                size="small"
                onChange={(e, newVal) =>
                  setFilters({ ...filters, languages: newVal })
                }
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <React.Fragment>{option.name}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Languages"
                    variant="outlined"
                    autoFocus
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off",
                      form: {
                        autocomplete: "off"
                      }
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
            {sources && sources.length > 0 && (
              <Autocomplete
                id="country-select-demo"
                options={sources.sort((a, b) => a.name.localeCompare(b.name))}
                disableClearable={false}
                clearOnBlur={true}
                classes={{
                  option: classes.option
                }}
                multiple
                defaultValue={[]}
                autoHighlight
                size="small"
                onChange={(e, newVal) =>
                  setFilters({ ...filters, sources: newVal })
                }
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <React.Fragment>
                    {option.name} - ({option.url})
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sources"
                    variant="outlined"
                    autoFocus
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off" // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  loading: state.articles.loading,
  dashboard_q: state.articles.dashboard_q
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(DashboardFilters);
