export const getDomain = (url) => {
  const { parseDomain, fromUrl } = require("parse-domain");
  let search;
  if (
    url.indexOf("facebook.com") === -1 &&
    url.indexOf("twitter.com") === -1 &&
    url.indexOf("pinterest.com") === -1 &&
    url.indexOf("issuu.com") === -1
  )
    search = fromUrl(url)
      .replace("www.", "")
      .replace(/^(m\.)/gi, "")
      .replace(/^(mobile\.)/gi, "");
  else {
    let searchTmp = url.replace("http://", "").replace("https://", "");
    search = searchTmp.split("/")[0] + "/" + searchTmp.split("/")[1];
    search = search.replace("www.", "");
  }

  return search;
};

export const isRelevantSource = (source) => {
  if (source.is_relevant) return true;
  if ([3, 4, 5, 8, 9, 10, 11].indexOf(source.source_type_id) !== -1)
    return true;

  return false;
};

export const uncaps = (str) => {
  const terms = [
    "GLBT",
    "LBQ",
    "LBQ+",
    "LBT",
    "LGBT",
    "LGBT+",
    "LGBTI",
    "LGBTI+",
    "LGBTIQ",
    "LGBTIQ+",
    "LGBTIQA",
    "LGBTIQA+",
    "LGBTIQAPD",
    "LGBTIQAPD+",
    "LGBTQ",
    "LGBTQ+",
    "LGBTQ2S",
    "LGBTQ2S+",
    "LGBTQI",
    "LGBTQI",
    "LGBTQIA",
    "LGTB",
    "LGTB+",
    "LGTBI",
    "LGTBI+"
  ];
  if (str && str === str.toUpperCase()) {
    let allStr = str.split(" ");
    let newStr = "";
    let i = 0;
    for (let strTmp of allStr) {
      if (terms.indexOf(strTmp) !== -1) {
        newStr += " " + strTmp;
      } else {
        strTmp = strTmp.toLowerCase();
        if (i === 0) {
          newStr += " " + strTmp.charAt(0).toUpperCase() + strTmp.slice(1);
        } else {
          newStr += " " + strTmp;
        }
        i++;
      }
    }
    return newStr;
  } else {
    return str;
  }
};

export const validURL = (str) => {
  var pattern = new RegExp(
    "^(https://|http://|ftp://)" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};
