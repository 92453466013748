import { combineReducers } from "redux";
import users from "./users";
import iwm_users from "./iwm_users";
import related from "./related";
import sources from "./sources";
import articles from "./articles";
import tags from "./tags";
import exclusion_rules from "./exclusion_rules";

export default combineReducers({
  users,
  related,
  sources,
  articles,
  tags,
  iwm_users,
  exclusion_rules
});
